import React from 'react';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import BannerBottomImg from 'src/images/general-cashback-202212/banner-bottom.png';
import BannerBottomSpImg from 'src/images/general-cashback-202212/banner-bottom-sp.png';
import { Hidden } from '@material-ui/core';
import styles from './BannerBottom.module.scss';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';
const BANNER_URL = isProduction
  ? 'https://paidy.com/campaign/lottery202212/'
  : 'https://paidy-staging.com/campaign/lottery202212/';

export default function BannerBottom() {
  return (
    <section className={styles.section}>
      <h1 className={styles.title}>
        3回に1回当たるキャッシュバック
        <br />
        キャンペーンも実施中
      </h1>
      <p className={styles.text}>
        お得にお買い物できる今だけのチャンスをお見逃しなく！
      </p>
      <OutboundLink href={BANNER_URL} target="_blank" rel="noopener noreferrer">
        <Hidden xsDown>
          <img
            alt="banner-bottom"
            src={BannerBottomImg}
            className={styles.img}
          />
        </Hidden>
        <Hidden smUp>
          <img
            alt="banner-bottom"
            src={BannerBottomSpImg}
            className={styles.img}
          />
        </Hidden>
      </OutboundLink>
    </section>
  );
}
