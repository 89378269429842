import React from 'react';
import { SITE_METADATA } from 'src/constants';
import Layout from 'src/components/Layout/Layout';
import JulyPayLater from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPayLater';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/TravelsSummerVol1/SectionCampaignFooter';
import JulyPaymentSteps from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyPaymentSteps';
import JulyCondition from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyConditions';
import JulyAppDownload from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyAppDownload';
import JulyWhyPaidy from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyWhyPaidy';
import JulyTopBanner from 'src/components/Merchant/Campaign/GeneralCashback202212/JulyTopBanner';
import BannerBottom from 'src/components/Merchant/Campaign/GeneralCashback202212/BannerBottom';

const SEOProps = {
  title: SITE_METADATA.general_cashback_202212.title,
  description: SITE_METADATA.general_cashback_202212.description,
};

const GeneralCashback202212 = () => {
  return (
    <Layout SEOProps={SEOProps} hasNoHeader hasNoFooter>
      <JulyTopBanner />
      <JulyPayLater />
      <JulyPaymentSteps />
      <JulyCondition />
      <BannerBottom />
      <JulyWhyPaidy />
      <JulyAppDownload />
      <SectionCampaignFooter isThemeBlack />
    </Layout>
  );
};

export default GeneralCashback202212;
